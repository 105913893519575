import React from "react";

function FAQ() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center">
          Frequently asked questions
        </h2>
        <div className="mt-12">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 ">
            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What is headless commerce?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Headless commerce architecture decouples a Storefront from the
                e-commerce functionality (CMS, Product Inventory Management,
                Checkout). Developers can utilize their frontend technology of
                choice to deliver a high-quality user experience. At the same
                time, use an e-commerce solution (like Shopify) that manages all
                commerce functions.
              </dd>
            </div>

            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What is Gatsby JS?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Gatsby Storefront is made with GatsbyJS modern website framework
                to build speedy and performant websites. It leverages modern
                Jamstack technologies like React, GraphQL, and many more.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
