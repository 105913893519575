import React from "react";

function LogoCloud() {
  return (
    <div className="bg-white hidden lg:block">
      <div className=" mx-auto py-4 px-4 sm:px-6 lg:px-8">
        {/* <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
              Trusted by over 5 very average small businesses
            </p> */}
        <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-3">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <svg
              className="h-12 text-gray-500"
              fill="#9CA3AF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 446.3 127.5"
            >
              <g>
                <g>
                  <path
                    id="s_5_"
                    d="M154.4,70.9c-3.8-2.1-5.8-3.8-5.8-6.2c0-3.1,2.7-5,7-5c5,0,9.4,2.1,9.4,2.1l3.5-10.7c0,0-3.2-2.5-12.7-2.5
			c-13.2,0-22.4,7.6-22.4,18.2c0,6,4.3,10.6,10,13.9c4.6,2.6,6.2,4.5,6.2,7.2c0,2.9-2.3,5.2-6.6,5.2c-6.4,0-12.4-3.3-12.4-3.3
			l-3.7,10.7c0,0,5.6,3.7,14.9,3.7c13.6,0,23.3-6.7,23.3-18.7C165.3,78.9,160.3,74.3,154.4,70.9z"
                  />
                  <path
                    id="h_5_"
                    d="M208.6,48.4c-6.7,0-11.9,3.2-16,8l-0.2-0.1l5.8-30.4H183l-14.7,77.3h15.1l5-26.4c2-10,7.1-16.1,11.9-16.1
			c3.4,0,4.7,2.3,4.7,5.6c0,2.1-0.2,4.6-0.7,6.7l-5.7,30.3h15.1l5.9-31.2c0.7-3.3,1.1-7.2,1.1-9.9C220.9,53.5,216.5,48.4,208.6,48.4
			z"
                  />
                  <path
                    id="o_5_"
                    d="M255.1,48.4c-18.2,0-30.3,16.4-30.3,34.7c0,11.7,7.2,21.2,20.8,21.2c17.9,0,29.9-16,29.9-34.7
			C275.6,58.7,269.3,48.4,255.1,48.4z M247.7,92.6c-5.2,0-7.3-4.4-7.3-9.9c0-8.7,4.5-22.8,12.7-22.8c5.4,0,7.1,4.6,7.1,9.1
			C260.2,78.4,255.7,92.6,247.7,92.6z"
                  />
                  <path
                    id="p_5_"
                    d="M314.3,48.4c-10.2,0-16,9-16,9h-0.2l0.9-8.1h-13.4c-0.7,5.5-1.9,13.8-3.1,20.1L272,124.6h15.1l4.2-22.4h0.3
			c0,0,3.1,2,8.9,2c17.8,0,29.4-18.2,29.4-36.6C329.9,57.5,325.4,48.4,314.3,48.4z M299.9,92.9c-3.9,0-6.2-2.2-6.2-2.2l2.5-14.1
			c1.8-9.4,6.7-15.7,11.9-15.7c4.6,0,6,4.3,6,8.3C314.1,78.9,308.3,92.9,299.9,92.9z"
                  />
                  <path
                    id="dot_5_"
                    d="M351.5,26.7c-4.8,0-8.7,3.8-8.7,8.8c0,4.5,2.8,7.6,7.1,7.6h0.2c4.7,0,8.8-3.2,8.9-8.8
			C359.1,29.8,356.1,26.7,351.5,26.7z"
                  />
                  <polygon
                    id="i_5_"
                    points="330.3,103.2 345.5,103.2 355.8,49.6 340.5,49.6 		"
                  />
                  <path
                    id="f_5_"
                    d="M394.2,49.5h-10.5l0.5-2.5c0.9-5.2,3.9-9.8,9-9.8c2.7,0,4.8,0.8,4.8,0.8l3-11.8c0,0-2.6-1.3-8.2-1.3
			c-5.4,0-10.7,1.5-14.8,5c-5.2,4.4-7.6,10.7-8.8,17.1l-0.4,2.5h-7l-2.2,11.4h7l-8,42.3h15.1l8-42.3h10.4L394.2,49.5z"
                  />
                  <path
                    id="y_5_"
                    d="M430.6,49.6c0,0-9.5,23.8-13.7,36.8h-0.2c-0.3-4.2-3.7-36.8-3.7-36.8h-15.9l9.1,49.2c0.2,1.1,0.1,1.8-0.3,2.5
			c-1.8,3.4-4.7,6.7-8.2,9.1c-2.8,2.1-6,3.4-8.5,4.3l4.2,12.8c3.1-0.7,9.4-3.2,14.8-8.2c6.9-6.5,13.3-16.4,19.8-30l18.5-39.7H430.6z
			"
                  />
                </g>
                <g>
                  <path
                    d="M74.8,14.8c0,0-1.4,0.4-3.7,1.1c-0.4-1.3-1-2.8-1.8-4.4c-2.6-5-6.5-7.7-11.1-7.7c0,0,0,0,0,0c-0.3,0-0.6,0-1,0.1
			c-0.1-0.2-0.3-0.3-0.4-0.5c-2-2.2-4.6-3.2-7.7-3.1c-6,0.2-12,4.5-16.8,12.2c-3.4,5.4-6,12.2-6.7,17.5c-6.9,2.1-11.7,3.6-11.8,3.7
			c-3.5,1.1-3.6,1.2-4,4.5c-0.3,2.5-9.5,72.9-9.5,72.9l75.6,13.1V14.7C75.3,14.7,75,14.8,74.8,14.8z M57.3,20.2
			c-4,1.2-8.4,2.6-12.7,3.9c1.2-4.7,3.6-9.4,6.4-12.5c1.1-1.1,2.6-2.4,4.3-3.2C57,12,57.4,16.9,57.3,20.2z M49.1,4.4
			c1.4,0,2.6,0.3,3.6,0.9c-1.6,0.8-3.2,2.1-4.7,3.6c-3.8,4.1-6.7,10.5-7.9,16.6c-3.6,1.1-7.2,2.2-10.5,3.2
			C31.8,19.1,39.9,4.6,49.1,4.4z M37.5,59.4c0.4,6.4,17.3,7.8,18.3,22.9c0.7,11.9-6.3,20-16.4,20.6c-12.2,0.8-18.9-6.4-18.9-6.4
			l2.6-11c0,0,6.7,5.1,12.1,4.7c3.5-0.2,4.8-3.1,4.7-5.1c-0.5-8.4-14.3-7.9-15.2-21.7C23.9,51.8,31.5,40.1,48.3,39
			c6.5-0.4,9.8,1.2,9.8,1.2l-3.8,14.4c0,0-4.3-2-9.4-1.6C37.5,53.5,37.4,58.2,37.5,59.4z M61.3,19c0-3-0.4-7.3-1.8-10.9
			c4.6,0.9,6.8,6,7.8,9.1C65.5,17.7,63.5,18.3,61.3,19z"
                  />
                  <path d="M78.2,124l31.4-7.8c0,0-13.5-91.3-13.6-91.9c-0.1-0.6-0.6-1-1.1-1c-0.5,0-9.3-0.2-9.3-0.2s-5.4-5.2-7.4-7.2V124z" />
                </g>
              </g>
            </svg>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            {/* AWS Logo */}
            <svg
              className="h-12 p-1 text-gray-500"
              fill="#9CA3AF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 304 182"
            >
              <g>
                <path
                  className="st0"
                  d="M86.4,66.4c0,3.7,0.4,6.7,1.1,8.9c0.8,2.2,1.8,4.6,3.2,7.2c0.5,0.8,0.7,1.6,0.7,2.3c0,1-0.6,2-1.9,3l-6.3,4.2
		c-0.9,0.6-1.8,0.9-2.6,0.9c-1,0-2-0.5-3-1.4C76.2,90,75,88.4,74,86.8c-1-1.7-2-3.6-3.1-5.9c-7.8,9.2-17.6,13.8-29.4,13.8
		c-8.4,0-15.1-2.4-20-7.2c-4.9-4.8-7.4-11.2-7.4-19.2c0-8.5,3-15.4,9.1-20.6c6.1-5.2,14.2-7.8,24.5-7.8c3.4,0,6.9,0.3,10.6,0.8
		c3.7,0.5,7.5,1.3,11.5,2.2v-7.3c0-7.6-1.6-12.9-4.7-16c-3.2-3.1-8.6-4.6-16.3-4.6c-3.5,0-7.1,0.4-10.8,1.3c-3.7,0.9-7.3,2-10.8,3.4
		c-1.6,0.7-2.8,1.1-3.5,1.3c-0.7,0.2-1.2,0.3-1.6,0.3c-1.4,0-2.1-1-2.1-3.1v-4.9c0-1.6,0.2-2.8,0.7-3.5c0.5-0.7,1.4-1.4,2.8-2.1
		c3.5-1.8,7.7-3.3,12.6-4.5c4.9-1.3,10.1-1.9,15.6-1.9c11.9,0,20.6,2.7,26.2,8.1c5.5,5.4,8.3,13.6,8.3,24.6V66.4z M45.8,81.6
		c3.3,0,6.7-0.6,10.3-1.8c3.6-1.2,6.8-3.4,9.5-6.4c1.6-1.9,2.8-4,3.4-6.4c0.6-2.4,1-5.3,1-8.7v-4.2c-2.9-0.7-6-1.3-9.2-1.7
		c-3.2-0.4-6.3-0.6-9.4-0.6c-6.7,0-11.6,1.3-14.9,4c-3.3,2.7-4.9,6.5-4.9,11.5c0,4.7,1.2,8.2,3.7,10.6
		C37.7,80.4,41.2,81.6,45.8,81.6z M126.1,92.4c-1.8,0-3-0.3-3.8-1c-0.8-0.6-1.5-2-2.1-3.9L96.7,10.2c-0.6-2-0.9-3.3-0.9-4
		c0-1.6,0.8-2.5,2.4-2.5h9.8c1.9,0,3.2,0.3,3.9,1c0.8,0.6,1.4,2,2,3.9l16.8,66.2l15.6-66.2c0.5-2,1.1-3.3,1.9-3.9c0.8-0.6,2.2-1,4-1
		h8c1.9,0,3.2,0.3,4,1c0.8,0.6,1.5,2,1.9,3.9l15.8,67l17.3-67c0.6-2,1.3-3.3,2-3.9c0.8-0.6,2.1-1,3.9-1h9.3c1.6,0,2.5,0.8,2.5,2.5
		c0,0.5-0.1,1-0.2,1.6c-0.1,0.6-0.3,1.4-0.7,2.5l-24.1,77.3c-0.6,2-1.3,3.3-2.1,3.9c-0.8,0.6-2.1,1-3.8,1h-8.6c-1.9,0-3.2-0.3-4-1
		c-0.8-0.7-1.5-2-1.9-4L156,23l-15.4,64.4c-0.5,2-1.1,3.3-1.9,4c-0.8,0.7-2.2,1-4,1H126.1z M254.6,95.1c-5.2,0-10.4-0.6-15.4-1.8
		c-5-1.2-8.9-2.5-11.5-4c-1.6-0.9-2.7-1.9-3.1-2.8c-0.4-0.9-0.6-1.9-0.6-2.8v-5.1c0-2.1,0.8-3.1,2.3-3.1c0.6,0,1.2,0.1,1.8,0.3
		c0.6,0.2,1.5,0.6,2.5,1c3.4,1.5,7.1,2.7,11,3.5c4,0.8,7.9,1.2,11.9,1.2c6.3,0,11.2-1.1,14.6-3.3c3.4-2.2,5.2-5.4,5.2-9.5
		c0-2.8-0.9-5.1-2.7-7c-1.8-1.9-5.2-3.6-10.1-5.2L246,52c-7.3-2.3-12.7-5.7-16-10.2c-3.3-4.4-5-9.3-5-14.5c0-4.2,0.9-7.9,2.7-11.1
		c1.8-3.2,4.2-6,7.2-8.2c3-2.3,6.4-4,10.4-5.2c4-1.2,8.2-1.7,12.6-1.7c2.2,0,4.5,0.1,6.7,0.4c2.3,0.3,4.4,0.7,6.5,1.1
		c2,0.5,3.9,1,5.7,1.6c1.8,0.6,3.2,1.2,4.2,1.8c1.4,0.8,2.4,1.6,3,2.5c0.6,0.8,0.9,1.9,0.9,3.3v4.7c0,2.1-0.8,3.2-2.3,3.2
		c-0.8,0-2.1-0.4-3.8-1.2c-5.7-2.6-12.1-3.9-19.2-3.9c-5.7,0-10.2,0.9-13.3,2.8c-3.1,1.9-4.7,4.8-4.7,8.9c0,2.8,1,5.2,3,7.1
		c2,1.9,5.7,3.8,11,5.5l14.2,4.5c7.2,2.3,12.4,5.5,15.5,9.6c3.1,4.1,4.6,8.8,4.6,14c0,4.3-0.9,8.2-2.6,11.6
		c-1.8,3.4-4.2,6.4-7.3,8.8c-3.1,2.5-6.8,4.3-11.1,5.6C264.4,94.4,259.7,95.1,254.6,95.1z"
                />
                <g>
                  <path
                    className="st1"
                    d="M273.5,143.7c-32.9,24.3-80.7,37.2-121.8,37.2c-57.6,0-109.5-21.3-148.7-56.7c-3.1-2.8-0.3-6.6,3.4-4.4
			c42.4,24.6,94.7,39.5,148.8,39.5c36.5,0,76.6-7.6,113.5-23.2C274.2,133.6,278.9,139.7,273.5,143.7z"
                  />
                  <path
                    className="st1"
                    d="M287.2,128.1c-4.2-5.4-27.8-2.6-38.5-1.3c-3.2,0.4-3.7-2.4-0.8-4.5c18.8-13.2,49.7-9.4,53.3-5
			c3.6,4.5-1,35.4-18.6,50.2c-2.7,2.3-5.3,1.1-4.1-1.9C282.5,155.7,291.4,133.4,287.2,128.1z"
                  />
                </g>
              </g>
            </svg>
          </div>

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            {/* Gatsby Logo  */}

            <svg
              className="h-12 text-gray-500"
              fill="#9CA3AF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 106 28"
            >
              <g>
                <path
                  d="M62.9000015,12h2.8000031v10h-2.7999992v-1.2999992c-1,1.5-2.2999992,1.6000004-3.0999985,1.6000004
		c-3.0999985,0-5.0999985-2.3999996-5.0999985-5.3000011c0-3,2-5.3000002,4.9000015-5.3000002
		c0.7999992,0,2.2999992,0.1000004,3.2000008,1.6000004L62.9000015,12L62.9000015,12z M57.7000008,17
		c0,1.6000004,1.0999985,2.7999992,2.7999992,2.7999992c1.5999985,0,2.7999992-1.2000008,2.7999992-2.7999992
		c0-1.6000004-1.0999985-2.8000002-2.7999992-2.8000002C58.9000015,14.1999998,57.7000008,15.3999996,57.7000008,17z"
                />
                <path
                  d="M71.1999969,14.3999996V22h-2.8000031v-7.6000004h-1.0999985V12h1.0999985V8.6000004h2.8000031V12h1.9000015v2.3999996
		H71.1999969z"
                />
                <path
                  d="M79.6999969,14.3999996C79,13.7999992,78.3999939,13.6999998,78.0999985,13.6999998C77.4000015,13.6999998,77,14,77,14.5
		c0,0.3000002,0.0999985,0.6000004,0.9000015,0.8999996l0.6999969,0.1999998c0.8000031,0.3000002,2,0.5999994,2.5,1.4000006
		c0.3000031,0.3999996,0.5,1,0.5,1.7000008c0,0.8999996-0.3000031,1.7999992-1.0999985,2.5
		c-0.8000031,0.7000008-1.8000031,1.1000004-3,1.1000004c-2.0999985,0-3.1999969-1-3.9000015-1.7000008l1.5-1.7000008
		C75.6999969,19.5,76.5,20.1000004,77.2999954,20.1000004c0.8000031,0,1.4000015-0.3999996,1.4000015-1.1000004
		c0-0.6000004-0.5-0.8999996-0.9000015-1l-0.5999985-0.2000008C76.5,17.5,75.6999969,17.1999989,75.0999985,16.5999985
		c-0.5-0.5-0.8000031-1.1000004-0.8000031-1.8999996c0-1,0.5-1.8000002,1-2.3000002
		c0.8000031-0.6000004,1.8000031-0.6999998,2.5999985-0.6999998c0.6999969,0,1.9000015,0.1000004,3.1999969,1.1000004
		L79.6999969,14.3999996z"
                />
                <path
                  d="M85.8000031,13.3000002c1-1.3999996,2.4000015-1.6000004,3.1999969-1.6000004
		C91.9000015,11.6999998,93.9000015,14,93.9000015,17s-2,5.2999992-5,5.2999992
		c-0.5999985,0-2.0999985-0.1000004-3.1999969-1.6000004V22H83V5.1999998h2.8000031V13.3000002z M85.5,17
		c0,1.6000004,1.0999985,2.7999992,2.8000031,2.7999992c1.5999985,0,2.8000031-1.2000008,2.8000031-2.7999992
		c0-1.6000004-1.0999985-2.8000002-2.8000031-2.8000002S85.5,15.3999996,85.5,17z"
                />
                <path d="M98.5,20.5L93.6999969,12H97l3.0999985,5.7000008l2.8000031-5.6999998h3.1999969l-8,15.3000002h-3.1999969L98.5,20.5z" />
                <path
                  d="M54,13.6999998h-2.7999992H47V16.5h3.7000008c-0.5999985,1.8999996-2,3.2000008-4.5999985,3.2000008
		c-2.9000015,0-5-2.3999996-5-5.3000002S43.0999985,9,46,9c1.5999985,0,3.2000008,0.8000002,4.2000008,2.1000004l2.2999992-1.5
		C51,7.5,48.5999985,6.3000002,46,6.3000002c-4.4000015,0-8,3.5999994-8,8.1000004S41.4000015,22.5,46,22.5s8-3.6000004,8-8.1000004
		C54.0999985,14.1000004,54,13.8999996,54,13.6999998z"
                />
              </g>
              <g>
                <path
                  d="M14,0C6.3000002,0,0,6.3000002,0,14s6.3000002,14,14,14s14-6.2999992,14-14S21.7000008,0,14,0z M6.1999998,21.7999992
		C4.0999999,19.7000008,3,16.8999996,3,14.1999998L13.8999996,25C11.1000004,24.8999996,8.3000002,23.8999996,6.1999998,21.7999992z
		 M16.3999996,24.7000008L3.3,11.6000004C4.4000001,6.6999998,8.8000002,3,14,3c3.7000008,0,6.8999996,1.8000002,8.8999996,4.5
		l-1.5,1.3000002C19.7000008,6.5,17,5,14,5c-3.8999996,0-7.1999998,2.5-8.5,6L17,22.5c2.8999996-1,5.1000004-3.5,5.7999992-6.5H18
		v-2h7C25,19.2000008,21.2999992,23.6000004,16.3999996,24.7000008z"
                />
              </g>
            </svg>
          </div>

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            {/* Contentful Logo */}
            <svg
              className="h-12 text-gray-500"
              fill="#9CA3AF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 656 149.3"
            >
              <path
                className="st0"
                d="M187.9,90.9c3.9-3,4.5-1.3,7.9,2.1c1.7,1.7,6.1,4.6,3.3,7.1c-12.7,10.9-31.9,9.5-42.8-3.2
	c-5-5.8-7.6-13.2-7.3-20.8c0.2-8.1,3.8-15.7,9.8-21c5.7-4.7,13-7.1,20.4-6.9c3.7,0,7.3,0.7,10.7,2.1c2.3,0.9,4.5,2,6.5,3.5
	c4,3,2.2,4.9-1,8l-2.1,2.1c-1.6,1.6-2.7,2.7-4.8,1.3c-4-3-9.2-4.1-14.1-3c-15.7,4.1-14.8,32,4.4,32C182.2,94,185.3,92.9,187.9,90.9
	L187.9,90.9z M624,106.2c-2.6,0-4.8-2.1-4.8-4.8V39.6c0-2.6,2.1-4.8,4.8-4.8h5.1c2.6,0,4.8,2.1,4.8,4.8v61.9c0,2.6-2.1,4.8-4.8,4.8
	L624,106.2z M597.1,99.2c-4.8,5.2-11.7,8.1-18.8,8c-16,0-23.6-13.2-23.6-28V54.1c0-2.6,2.1-4.8,4.8-4.8h5.1c2.6,0,4.8,2.1,4.8,4.8
	v25c-0.9,7.2,4.2,13.7,11.3,14.6c0.6,0.1,1.2,0.1,1.9,0.1c8-0.2,14.3-6.9,14.1-14.8c0-0.2,0-0.4,0-0.6v-9c0-0.3,0-0.7,0-1V54.1
	c0-2.6,2.1-4.8,4.8-4.8h5.1c2.6,0,4.8,2.1,4.8,4.8v47.5c0,2.6-2.1,4.7-4.7,4.7l0,0h-4.3c-2.6-0.1-4.6-2.1-4.7-4.7v-2.4H597.1z
	 M535.7,49.5h10c2.6,0,4.7,2.2,4.7,4.8v3.3c0,2.6-2.1,4.8-4.7,4.8h-10v39.1c0,2.6-2.1,4.8-4.8,4.8h-5.1c-2.6,0-4.8-2.1-4.8-4.8v-54
	c-1-11.3,7.3-21.2,18.5-22.2c0.1,0,0.1,0,0.2,0h7.8c2.6,0,4.7,2.2,4.7,4.8v3c0,2.6-2.1,4.8-4.7,4.8h-3.8c-5.7,0-8.1,4.4-8.1,9.7
	L535.7,49.5L535.7,49.5z M500.1,49.5H511c2.6,0,4.7,2.1,4.7,4.7l0,0v3.2c0,2.6-2.1,4.7-4.7,4.7l0,0h-10.9V86c0,4.6,2.2,7.3,6.9,7.3
	h2.2c2.6,0,4.7,2.1,4.7,4.7l0,0v3.2c0,2.6-2.1,4.7-4.7,4.7l0,0h-6.6c-11.8-0.7-17.1-8.4-17.1-19.9V39.7c0-2.6,2.1-4.7,4.7-4.7l0,0h5
	c2.6,0,4.7,2.1,4.7,4.7l0,0L500.1,49.5L500.1,49.5z M342.3,49.5h10.9c2.6,0,4.7,2.1,4.7,4.7v3.2c0,2.6-2.1,4.7-4.7,4.7h-10.6V86
	c0,4.6,2.2,7.3,6.9,7.3h2.2c2.6,0,4.7,2.1,4.7,4.7v3.2c0,2.6-2.1,4.7-4.7,4.7h-3.3c-12.9,0-20.3-5.5-20.3-20V39.7
	c0-2.6,2.1-4.7,4.7-4.7h5c2.6,0,4.7,2.1,4.7,4.7v9.8H342.3z M278.3,56.4c4.7-5,11.3-7.9,18.2-8c15.9,0,24.3,13.1,24.3,28v25.1
	c0,2.6-2.1,4.8-4.8,4.8l0,0h-5.1c-2.6,0-4.8-2.1-4.8-4.8l0,0v-25c0.9-7.2-4.2-13.7-11.3-14.6c-0.6-0.1-1.3-0.1-1.9-0.1
	c-8,0.2-14.3,6.9-14,14.8c0,0.2,0,0.3,0,0.5v24.3c0,2.6-2.1,4.7-4.7,4.7h-5c-2.6,0-4.7-2.1-4.7-4.7V54c0-2.6,2.1-4.7,4.7-4.7h4.3
	c2.6,0.1,4.6,2.1,4.7,4.7L278.3,56.4L278.3,56.4z M436.2,56.4c4.7-5,11.3-7.9,18.2-8c15.9,0,24.3,13.1,24.3,28v25.1
	c0,2.6-2.1,4.8-4.8,4.8h-5.1c-2.6,0-4.8-2.1-4.8-4.8v-25c0.9-7.2-4.2-13.7-11.3-14.6c-0.6-0.1-1.3-0.1-1.9-0.1
	c-8,0.2-14.3,6.9-14,14.8c0,0.2,0,0.3,0,0.5v24.3c0,2.6-2.1,4.7-4.7,4.7H427c-2.6,0-4.7-2.1-4.7-4.7V54c0-2.6,2.1-4.7,4.7-4.7h4.3
	c2.6,0.1,4.6,2.1,4.7,4.7v2.5L436.2,56.4z M229.8,48.2c-15.8-0.5-29,11.8-29.5,27.6c0,0.7,0,1.4,0,2.1c0,16.4,13.3,29.6,29.6,29.6
	s29.6-13.3,29.6-29.6c0.5-15.9-11.9-29.2-27.8-29.7C231.1,48.2,230.4,48.2,229.8,48.2z M373.4,83.2c1.8,7.3,8.8,12.2,16.3,11.2
	c3.7-0.1,7.4-0.9,10.7-2.4l1.6-0.8c1.8-1,3.4-1.6,5,0l2.6,2.6c0.8,0.8,1.6,1.7,2.3,2.5c2.4,3,0.7,3.8-2,5.6
	c-6.1,4-13.2,6.1-20.5,6.1c-18.8,0-31.3-11.4-31.3-30.4c-0.7-15.7,11.4-29.1,27.2-29.8c1.1,0,2.1,0,3.2,0c18.2,0,28.7,10.7,29,29
	c0,5,0.3,6.6-4.9,6.6L373.4,83.2z M230.3,61.5c8.6,0.2,15.4,7.3,15.2,15.9c0,0.1,0,0.3,0,0.4c0.7,8.2-5.3,15.5-13.6,16.3
	c-8.2,0.7-15.5-5.3-16.3-13.6c-0.1-0.9-0.1-1.8,0-2.7c-0.7-8.3,5.5-15.6,13.8-16.2c0.4,0,0.8,0,1.1,0L230.3,61.5z M374.1,70.8h29.2
	c-1.2-6.7-7.5-11.2-14.2-10.2C382.4,60.3,376.3,64.5,374.1,70.8L374.1,70.8z"
              />
              <g>
                <path
                  className="st1"
                  d="M100.6,52.6c0,0,9.8,11.3,19.7,0.9s-5.8-21.6-5.8-21.6S82.1,3,43.2,32.2c0,0-21.3,16.3-20.3,46.6
		c1,30.3,26.5,44.6,26.5,44.6s30.9,20.9,63.6-2.5c0,0,15.9-8.4,6.8-22.3c0,0-9-10.1-16.8-1.6s-28,17.7-46.1,0.7s-8.3-52.3,20.4-53.4
		C77.3,44.3,89.6,42.7,100.6,52.6z"
                />
                <circle className="st1" cx="47.7" cy="44.4" r="12.2" />
                <circle className="st1" cx="48.3" cy="106.6" r="12.2" />
              </g>
            </svg>
          </div>

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            {/* Yotpo Logo  */}
            <svg
              className="h-12 text-gray-500"
              fill="#9CA3AF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 71.1 76"
            >
              <g id="logo">
                <g id="logo_1_">
                  <g>
                    <ellipse cx="36" cy="38.3" rx="33.1" ry="34.1" />
                  </g>
                </g>
              </g>
              <g>
                <polygon
                  fill="#FFFFFF"
                  points="17.5,25.1 21.4,25.1 24.4,30.6 27.3,25.1 31,25.1 26.1,33.9 26.1,38.6 22.5,38.6 22.5,34 	"
                />
                <path
                  fill="#FFFFFF"
                  d="M29.9,31.9c0-4.1,3.3-6.9,7.1-6.9c3.8,0,7.1,2.9,7.1,6.9c0,4.1-3.3,7-7.1,7C33.2,38.8,29.9,36,29.9,31.9
		 M40.4,31.9c0-2.1-1.3-3.8-3.5-3.8c-2.2,0-3.5,1.7-3.5,3.8c0,2.1,1.3,3.8,3.5,3.8C39.2,35.7,40.4,34,40.4,31.9"
                />
                <polygon
                  fill="#FFFFFF"
                  points="47.4,28.1 43.9,28.1 43.9,25.1 54.5,25.1 54.5,28.1 51,28.1 51,38.6 47.4,38.6 	"
                />
                <path
                  fill="#FFFFFF"
                  d="M22.1,53.4h-3.5V39.9h5c3.6,0,5.6,2.3,5.6,5.3c0,3-2,5.3-5.6,5.3h-1.5V53.4z M23.4,47.4
		c1.6,0,2.2-0.9,2.2-2.2c0-1.3-0.6-2.3-2.2-2.3h-1.3v4.5H23.4z"
                />
                <path
                  fill="#FFFFFF"
                  d="M29.8,46.7c0-4.1,3.3-6.9,7.1-6.9c3.8,0,7.1,2.9,7.1,6.9c0,4.1-3.3,7-7.1,7C33.1,53.6,29.8,50.8,29.8,46.7
		 M40.4,46.7c0-2.1-1.3-3.8-3.5-3.8c-2.2,0-3.5,1.7-3.5,3.8c0,2.1,1.3,3.8,3.5,3.8C39.1,50.5,40.4,48.8,40.4,46.7"
                />
                <path
                  fill="#FFFFFF"
                  d="M44,51.7c0-1.2,0.9-2,1.9-2c1,0,1.9,0.8,1.9,2c0,1.2-0.9,2-1.9,2C44.9,53.6,44,52.8,44,51.7"
                />
              </g>
            </svg>
          </div>

          {/* GraphQL logo */}
          {/* <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <svg
              className="h-12 text-gray-500"
              fill="#9CA3AF"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 400 400"
            >
              <g>
                <g>
                  <g>
                    <rect
                      x="122"
                      y="-0.4"
                      transform="matrix(-0.866 -0.5 0.5 -0.866 163.3196 363.3136)"
                      width="16.6"
                      height="320.3"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="39.8" y="272.2" width="320.3" height="16.6" />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="37.9"
                      y="312.2"
                      transform="matrix(-0.866 -0.5 0.5 -0.866 83.0693 663.3409)"
                      width="185"
                      height="16.6"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="177.1"
                      y="71.1"
                      transform="matrix(-0.866 -0.5 0.5 -0.866 463.3409 283.0693)"
                      width="185"
                      height="16.6"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="122.1"
                      y="-13"
                      transform="matrix(-0.5 -0.866 0.866 -0.5 126.7903 232.1221)"
                      width="16.6"
                      height="185"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="109.6"
                      y="151.6"
                      transform="matrix(-0.5 -0.866 0.866 -0.5 266.0828 473.3766)"
                      width="320.3"
                      height="16.6"
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="52.5" y="107.5" width="16.6" height="185" />
                  </g>
                </g>
                <g>
                  <g>
                    <rect x="330.9" y="107.5" width="16.6" height="185" />
                  </g>
                </g>
                <g>
                  <g>
                    <rect
                      x="262.4"
                      y="240.1"
                      transform="matrix(-0.5 -0.866 0.866 -0.5 126.7953 714.2875)"
                      width="14.5"
                      height="160.9"
                    />
                  </g>
                </g>
                <path
                  d="M369.5,297.9c-9.6,16.7-31,22.4-47.7,12.8c-16.7-9.6-22.4-31-12.8-47.7c9.6-16.7,31-22.4,47.7-12.8
		C373.5,259.9,379.2,281.2,369.5,297.9"
                />
                <path
                  d="M90.9,137c-9.6,16.7-31,22.4-47.7,12.8c-16.7-9.6-22.4-31-12.8-47.7c9.6-16.7,31-22.4,47.7-12.8
		C94.8,99,100.5,120.3,90.9,137"
                />
                <path
                  d="M30.5,297.9c-9.6-16.7-3.9-38,12.8-47.7c16.7-9.6,38-3.9,47.7,12.8c9.6,16.7,3.9,38-12.8,47.7
		C61.4,320.3,40.1,314.6,30.5,297.9"
                />
                <path
                  d="M309.1,137c-9.6-16.7-3.9-38,12.8-47.7c16.7-9.6,38-3.9,47.7,12.8c9.6,16.7,3.9,38-12.8,47.7
		C340.1,159.4,318.7,153.7,309.1,137"
                />
                <path
                  d="M200,395.8c-19.3,0-34.9-15.6-34.9-34.9c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9
		C234.9,380.1,219.3,395.8,200,395.8"
                />
                <path
                  d="M200,74c-19.3,0-34.9-15.6-34.9-34.9c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9
		C234.9,58.4,219.3,74,200,74"
                />
              </g>
            </svg>
          </div>*/}
        </div>
      </div>
    </div>
  );
}

export default LogoCloud;
