import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import LogoCloud from "./LogoCloud";

function Hero() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-image.jpeg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <main className="lg:relative">
      <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-24 lg:text-left">
        <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
            <span className="block xl:inline">Build a blazing fast </span>
            <span className="block text-gradient bg-gradient-to-r from-blue-800 to-blue-600 xl:inline">
              Shopify store
            </span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            Your e-Commerce shop with Shopify, Contentful, Yotpo, and more with
            2000+ GatsbyJS plugins
          </p>
          <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
            <div className="rounded-md shadow">
              <a
                href="/request-a-demo/"
                className="w-full flex items-center justify-center px-8 py-3 border-0 border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:to-blue-500 md:py-4 md:text-lg md:px-10"
              >
                Speak to an Expert
              </a>
            </div>

            {/* <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a
                href="#"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                Live demo
              </a>
            </div> */}
          </div>

          <LogoCloud />
        </div>
      </div>
      <div className="hidden lg:block relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
        <Img
          className="absolute inset-0 w-full h-full object-cover"
          // src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixqx=hHUO5iZZ7b&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
          fluid={data.file.childImageSharp.fluid}
          alt=""
        />
      </div>
    </main>
  );
}

export default Hero;
