import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Hero from "../components/SharedTailwind/Hero";
import Features from "../components/SharedTailwind/Features2";
import SpeedTest from "../components/SharedTailwind/SpeedTest";
import Pricing from "../components/SharedTailwind/Pricing3";
import PH from "../components/SharedTailwind/PH";
import FAQ from "../components/SharedTailwind/FAQ";

function IndexMain(props) {
  return (
    <React.Fragment>
      <Helmet title={props.data.site.siteMetadata.title}>
        <meta
          name="description"
          content={props.data.site.siteMetadata.description}
        />
      </Helmet>

      {/* Hero Section  */}
      <Hero />

      {/* <LogoCloud /> */}

      {/* Feature */}
      <div id="features"></div>
      <Features />

      <SpeedTest />

      <Pricing />

      <PH />

      <FAQ />

      {/* <!-- This example requires Tailwind CSS v2.0+ --> */}
    </React.Fragment>
  );
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

export default IndexMain;
