import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql, Link } from "gatsby";

//     <!--
//   This example requires Tailwind CSS v2.0+

//   This example requires some changes to your config:

//   ```
//   // tailwind.config.js
//   module.exports = {
//     // ...
//     plugins: [
//       // ...
//       require('@tailwindcss/aspect-ratio'),
//     ]
//   }
//   ```
// -->
function PH() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ph-gatsby-shopify.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase ">
                Success Stories
              </h2>
              <h2 className="text-3xl font-extrabold  sm:text-4xl">
                <span className="block">Precision Hydration</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-gray-500 ">
                Learn how Precision Hydration, a gloabl leader in electrolyte
                supplements for athlets, created a International Headless
                Commerce store with Gatsby Storefront.
              </p>
              <Link
                to="/headless-commerce-shopify-precision-hydration"
                className="mt-8 bg-white border border-transparent rounded-md shadow px-6 py-3 inline-flex items-center text-base font-medium text-blue-600 hover:bg-blue-50"
              >
                Read the story
              </Link>
            </div>
          </div>
          <div
            className="-mt-6"
            // className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1"
          >
            <Img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              fluid={data.file.childImageSharp.fluid}
              alt="Gatsby JS Shopify screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PH;
