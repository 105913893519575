import React from "react";
import { Link } from "gatsby";

function Pricing() {
  return (
    <div className="bg-gradient-to-r from-blue-800 to-blue-700">
      <div id="pricing"></div>
      <div className="pt-12 sm:pt-16 ">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base text-white font-semibold tracking-wide uppercase text-center">
              Pricing
            </h2>
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              Start your Headless Commerce with Shopify
            </h2>
            <p className="mt-4 max-w-2xl text-xl text-blue-200 lg:mx-auto">
              Receive professional customization, great performance and
              integration with Shopify, Contentful, and Yotpo out-of-the-box.
            </p>
          </div>
        </div>
      </div>

      <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                {/* <h3 className="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  Lifetime Membership
                </h3> */}
                {/* <p className="mt-6 text-base text-gray-500">
                  Receive professional customization, great performance and
                  integration with Shopify, Contentful, and Yotpo
                  out-of-the-box.
                </p> */}
                {/* <div className="mt-8"> */}
                <div>
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-blue-600">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200"></div>
                  </div>
                  <ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        {/* <!-- Heroicon name: solid/check-circle --> */}
                        <svg
                          className="h-5 w-5 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm text-gray-700">
                        Professional customization
                      </p>
                    </li>

                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        {/* <!-- Heroicon name: solid/check-circle --> */}
                        <svg
                          className="h-5 w-5 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm text-gray-700">
                        Shopify Integration App
                      </p>
                    </li>

                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        {/* <!-- Heroicon name: solid/check-circle --> */}
                        <svg
                          className="h-5 w-5 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm text-gray-700">
                        Connection API (Contentful & Yotpo integration)
                      </p>
                    </li>

                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        {/* <!-- Heroicon name: solid/check-circle --> */}
                        <svg
                          className="h-5 w-5 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm text-gray-700">Cloud builds</p>
                    </li>

                    <li className="flex items-start lg:col-span-1">
                      <div className="flex-shrink-0">
                        {/* <!-- Heroicon name: solid/check-circle --> */}
                        <svg
                          className="h-5 w-5 text-green-400"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <p className="ml-3 text-sm text-gray-700">
                        Worldwide CDN hosting
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="mt-6">
                  <div className="rounded-md shadow">
                    <a
                      href="/request-a-demo/"
                      className="flex items-center justify-center px-5 py-3  border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-blue-800 to-blue-600 hover:to-blue-500"
                    >
                      Request a Quote
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center lg:text-left">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <div className="text-3xl font-extrabold tracking-tight  md:text-4xl">
              <span className="block text-white">
                Need a one-time custom development service?
              </span>
              <span className="block text-blue-200">We are here to help!</span>
              <p className="mt-4 max-w-3xl text-xl font-normal tracking-normal text-blue-200">
                Receive professional customization service from Gatsby
                Storefront developers.
              </p>
            </div>

            <div className="mt-8 flex justify-center lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="contact-us"
                  className="inline-flex items-center justify-center px-5 py-3 border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
